/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    makeStyles, Typography, useMediaQuery,
} from '@material-ui/core';
import React from 'react';

import { shape, string, object } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../../../../../state/ducks/Member/ducks/Auth/Plugin/auth';
import { emitCustomTrackingEvent } from '../../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import parseFontFamilyExtension from '../../../../../helpers/contentstack/parseFontFamilyExtension';

const useStyles = makeStyles((theme) => ({
    container: (props) => ({
        padding: '17px 0px',
        fontFamily: parseFontFamilyExtension(props?.main?.font_family) || 'Lato,sans-serif',
        color: props?.main?.font_color?.color || '#2f2f2f',
        backgroundColor: props?.background_color?.color || 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0px 24px',
        margin: '0px 15px',
        [theme.breakpoints.up(599)]: {
            gap: '0px 42px',
            margin: props?.margin || '0px',
        },
    }),
    copyText: (props) => ({
        fontFamily: 'inherit',
        fontSize: props?.main?.font_size || '16px',
        letterSpacing: '0.01em',
        [theme.breakpoints.up(599)]: {
            fontSize: props?.main?.font_size || '24px',
        },
    }),
    nameText: {
        fontWeight: 'bold',
    },
    button: (props) => ({
        fontFamily: parseFontFamilyExtension(props?.button?.font_family) || 'Lato,sans-serif',
        padding: '8px 20px',
        fontWeight: 'bold',
        backgroundColor: 'white',
        fontSize: props?.button?.font_size || '16px',
        border: '1px solid black',
        borderRadius: '3px',
        letterSpacing: '0.04em',
        cursor: 'pointer',
        minWidth: '100px',
        [theme.breakpoints.up(599)]: {
            borderWidth: '2px',
        },
    }),
}));

function GuestUserGreetingUI({ userName, blockData, salesForceCampaignResponse }) {
    const { main = {}, button = {} } = blockData;
    let main_copy = main?.copy || 'Welcome back, {user}. {newline}Sign In to get the best experience';
    if (!userName) {
        main_copy = 'Welcome back. {newline}Sign In to get the best experience';
    }

    const classes = useStyles(blockData);
    const dispatch = useDispatch();
    const isDesktopView = useMediaQuery('(min-width: 600px)');
    const { loginByRedirect } = useAuth();

    const handleSignIn = async () => {
        dispatch(emitCustomTrackingEvent({
            action: 'Click',
            salesforceResponse: salesForceCampaignResponse,
            page: { type: 'home' },
            eventSubType: 'campaign-tracking',
        }));
        await loginByRedirect({ prompt: 'login' });
    };

    const renderUserName = () => {
        if (main_copy?.includes('{user}')) {
            return userName;
        }
        return '';
    };

    const linesArr = main_copy?.split('{newline}') || [];
    let userArr = [];
    if (linesArr?.length > 0) {
        userArr = linesArr[0]?.split('{user}');
    }

    return (
        <div className={classes.container}>
            <div className={classes.textContainer}>
                {
                    isDesktopView ? (
                        <Typography component="p" className={classes.copyText}>
                            <span className={classes.nameText}>
                                {userArr[0] || ''}
                                {renderUserName()}
                                {userArr[1] || ''}
                            </span>
                            {linesArr[1] || ''}
                        </Typography>
                    )
                        : (
                            <>
                                <Typography component="p" className={`${classes.copyText} ${classes.nameText}`}>
                                    {userArr[0] || ''}
                                    {renderUserName()}
                                    {userArr[1] || ''}
                                </Typography>
                                {linesArr[1] && (
                                    <Typography component="p" className={classes.copyText}>
                                        {linesArr[1]}
                                    </Typography>
                                )}
                            </>
                        )
                }
            </div>
            <button
                type="button"
                className={classes.button}
                onClick={handleSignIn}
            >
                {button?.copy || (isDesktopView ? 'Sign In or Create an Account' : 'Sign In')}
            </button>
        </div>
    );
}

GuestUserGreetingUI.propTypes = {
    userName: string.isRequired,
    blockData: shape({
        font_family: string,
        font_size: string,
        margin: string,
        main_copy: string,
        button_copy: string,
        button_font_family: string,
        button_font_size: string,
        font_color: shape({
            color: string,
        }),
        background_color: shape({
            color: string,
        }),
    }),
    salesForceCampaignResponse: object.isRequired,
};

GuestUserGreetingUI.defaultProps = {
    blockData: {},
};
export default GuestUserGreetingUI;
