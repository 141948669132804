/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import mbpLogger from 'mbp-logger';
import { useSelector } from 'react-redux';
import {
    shape, string, bool, array,
} from 'prop-types';
// eslint-disable-next-line import/no-cycle
import GenericSkeleton from '../../GraphqlSkeletonComponents/GenericSkeleton';
import useSalesforcePersonalizedContentQuery from '../../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
// eslint-disable-next-line import/no-cycle
import HomePageBlock from '../../GraphqlHomePage/HomePageBlock';
import FallbackContentContainer from '../Common/FallbackContentContainer';
import { getIsBot } from '../../../../../state/ducks/App/App-Selectors';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const PersonalizedContentBlock = ({
    data: {
        content_type: contentType = '',
        content_types,
        pz_campaign_id: interactionName = '',
        content_zones,
        skeleton_height,
        skeleton_width,
        skeleton_margin = '0 auto',
        fallback_uid: fallbackUID = '',
    },
    ssrDeviceType,
    presentationFamily,
    brand,
    hasTopMargin,
}) => {
    const [showSkeleton, setShowSkeleton] = useState(true);
    // setShowSkeleton is used to show skeleton, until our components in homepageblock loads.

    const isBot = useSelector(getIsBot);
    const ffHoldoutPersonalizationEnabled = useSelector(getFeatureFlag('is-personalization-holdout-enabled'));

    if (isBot || ffHoldoutPersonalizationEnabled) {
        return (
            <FallbackContentContainer
                skeleton_height={skeleton_height}
                skeleton_width={skeleton_width}
                fallbackUID={fallbackUID}
                contentType={contentType}
                hasTopMargin={hasTopMargin}
            />
        );
    }

    if (typeof window === 'undefined') {
        return <GenericSkeleton height={skeleton_height} width={skeleton_width} maxWidth="unset" margin={skeleton_margin} id="PersonalizedContentBlock" />;
    }
    const targeting = {
        pageType: 'home',
    };
    if (content_zones?.length && content_zones?.[0]) {
        targeting.contentZones = content_zones;
    }

    const {
        loading, error, data, variables,
    } = useSalesforcePersonalizedContentQuery({
        queryName: 'PersonalizedContent',
        interactionName,
        targeting,
    });

    if (loading || variables?.skip) {
        return <GenericSkeleton height={skeleton_height} width={skeleton_width} maxWidth="unset" margin={skeleton_margin} />;
    }

    if (error) {
        mbpLogger.logError({
            function: `PersonalizedContentBlock - content_types = ${content_types.toString()} interactionName = ${interactionName}`,
            message: 'Error loading API data',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
        return (
            <FallbackContentContainer
                skeleton_height={skeleton_height}
                skeleton_width={skeleton_width}
                fallbackUID={fallbackUID}
                contentType={contentType}
                hasTopMargin={hasTopMargin}
            />
        );
    }

    if (data && data?.contentSF?.content) {
        const { campaign, content } = data?.contentSF;
        const sfContentType = campaign?.campaignResponses?.[0]?.payload?.cmsContentType || campaign?.campaignResponses?.[0]?.payload?.promotions?.[0]?.attributes?.cmsContentType?.value;
        const contentTypeValueObject = {};
        const contentTypesToIncludeRefs = ['generic_banner'];

        if (!sfContentType || !content_types.includes(sfContentType)) {
            mbpLogger.logError({
                function: `PersonalizedContentBlock - salesforce content_type = ${sfContentType} is not matching with CMS content_types entries = ${content_types.toString()}`,
                message: 'Salesforce content_type not matching with CMS content_types entries',
                appName: process.env.npm_package_name,
                module: 'PersonalizedContent',
            });
            return null;
        }

        if (sfContentType === 'gift_finder') contentTypeValueObject['gift_finder_reference'] = [content];
        if (contentTypesToIncludeRefs.includes(sfContentType)) contentTypeValueObject['reference'] = [content];

        const block = {
            [sfContentType]: sfContentType === 'gift_finder' || contentTypesToIncludeRefs.includes(sfContentType) ? contentTypeValueObject : content,
        };

        return (
            <>
                {showSkeleton && <GenericSkeleton height={skeleton_height} width={skeleton_width} id={sfContentType} maxWidth="unset" margin="0 auto" />}
                <HomePageBlock
                    block={block}
                    ssrDeviceType={ssrDeviceType}
                    presentationFamily={presentationFamily}
                    brand={brand}
                    hasTopMargin={hasTopMargin}
                    salesforceResponse={data?.contentSF}
                    setShowSkeleton={setShowSkeleton}
                    isFromPersonalizedContentBlock
                />
            </>
        );
    }

    return (
        <FallbackContentContainer
            skeleton_height={skeleton_height}
            skeleton_width={skeleton_width}
            fallbackUID={fallbackUID}
            contentType={contentType}
            hasTopMargin={hasTopMargin}
        />
    );
};

PersonalizedContentBlock.propTypes = {
    data: shape({
        content_types: array.isRequired,
    }).isRequired,
    ssrDeviceType: string,
    presentationFamily: string,
    brand: string,
    hasTopMargin: bool,
};

PersonalizedContentBlock.defaultProps = {
    ssrDeviceType: '',
    presentationFamily: '',
    brand: '',
    hasTopMargin: false,
};

export default PersonalizedContentBlock;
